import React, { useState, useEffect } from 'react';
import './App.css';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function App() {
  const [amcPrice, setAmcPrice] = useState({
    lastTrade: 0,
    lastTradeExt: 0
  });
  const [gmePrice, setGmePrice] = useState({
    lastTrade: 0,
    lastTradeExt: 0
  });

  useEffect(() => {
    const stockPrice = (ticker, setPrice) => {
      setTimeout(() => {
        fetch(`https://stockprice.khant.xyz/${ticker}`).then(amc => amc.text().then(resp => {
          const respJson = JSON.parse(resp);
          setPrice({
            lastTrade: respJson.lastTrade,
            lastTradeExt: respJson.lastTradeExt
          });
          stockPrice(ticker, setPrice);
        }));
      }, "1000");
    }

    stockPrice('AMC', setAmcPrice);
    stockPrice('GME', setGmePrice);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div>
          AMC: {USDollar.format(amcPrice.lastTrade)} | {USDollar.format(amcPrice.lastTradeExt)}
        </div>
        <div>
          GME: {USDollar.format(gmePrice.lastTrade)} | {USDollar.format(gmePrice.lastTradeExt)}
        </div>
      </header>
    </div>
  );
}

export default App;
